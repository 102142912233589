import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import PerfumeCard from './PerfumeCard';
import { FaSearch, FaFilter } from 'react-icons/fa';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  color: var(--text-color);
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const SearchBar = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
`;

const SearchInput = styled.input`
  padding: 10px 20px;
  border: 1px solid var(--secondary-color);
  border-radius: 25px;
  width: 300px;
  font-size: 16px;
  
  &:focus {
    outline: none;
    border-color: var(--primary-color);
  }
`;

const FilterButton = styled.button`
  background: white;
  border: 1px solid var(--secondary-color);
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  
  &:hover {
    background: var(--primary-color);
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }
`;
const PerfumeList = () => {
    const [perfumes, setPerfumes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Przykładowe dane (docelowo pobierane z API)
      const mockPerfumes = [
        {
          id: 1,
          name: "Light Blue",
          brand: "Dolce & Gabbana",
          rating: 4.5,
          reviews_count: 128,
          price: 399,
          image: `https://picsum.photos/200/200?random=1` // Używamy stałej wartości lub id perfum
        },
        {
          id: 2,
          name: "J'adore",
          brand: "Dior",
          rating: 4.8,
          reviews_count: 256,
          price: 499,
          image: `https://picsum.photos/200/200?random=2`
        },
        {
          id: 3,
          name: "La Vie Est Belle",
          brand: "Lancome",
          rating: 4.6,
          reviews_count: 189,
          price: 449,
          image: `https://picsum.photos/200/200?random=3`
        },
        {
          id: 4,
          name: "Coco Mademoiselle",
          brand: "Chanel",
          rating: 4.9,
          reviews_count: 312,
          price: 599,
          image: `https://picsum.photos/200/200?random=4`
        }
      ];
      
      setPerfumes(mockPerfumes);
      setLoading(false);
  
      // Docelowo użyj API:
      /*
      const fetchPerfumes = async () => {
        try {
          const response = await axios.get('http://localhost:8000/api.php/getPerfumes');
          setPerfumes(response.data);
        } catch (error) {
          console.error('Error fetching perfumes:', error);
          setPerfumes(mockPerfumes); // Fallback do danych mockowych
        } finally {
          setLoading(false);
        }
      };
  
      fetchPerfumes();
      */
    }, []);
  
    const filteredPerfumes = perfumes.filter(perfume =>
      perfume.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      perfume.brand.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    if (loading) {
      return (
        <Container>
          <div>Ładowanie...</div>
        </Container>
      );
    }
  
    return (
      <Container>
        <Header>
          <Title>Ranking Perfum</Title>
          <SearchBar>
            <SearchInput
              type="text"
              placeholder="Szukaj perfum..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FilterButton>
              <FaFilter /> Filtry
            </FilterButton>
          </SearchBar>
        </Header>
        <Grid>
          {filteredPerfumes.map(perfume => (
            <PerfumeCard key={perfume.id} perfume={perfume} />
          ))}
        </Grid>
      </Container>
    );
  };
  
  export default PerfumeList;