import React from 'react';
import styled from 'styled-components';
import { FaStar, FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Card = styled.div`
  background: white;
  border-radius: 15px;
  padding: 20px;
  margin: 15px 0;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const PerfumeImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  color: var(--text-color);
  margin-bottom: 8px;
`;

const Brand = styled.p`
  color: var(--secondary-color);
  font-weight: 500;
  margin-bottom: 8px;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  
  svg {
    color: gold;
    margin-right: 2px;
  }
`;

const Price = styled.p`
  font-weight: bold;
  color: var(--text-color);
  font-size: 1.2em;
`;

const Button = styled.button`
  background: var(--primary-color);
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background 0.2s;
  
  &:hover {
    background: var(--secondary-color);
  }
`;
const CardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const PerfumeCard = ({ perfume }) => {
  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar key={index} color={index < rating ? "gold" : "#ddd"} />
    ));
  };

  return (
    <CardLink to={`/perfume/${perfume.id}`}>
    <Card>
      <FlexContainer>
        <PerfumeImage 
          src={perfume.image || 'https://picsum.photos/150'} 
          alt={perfume.name} 
        />
        <InfoContainer>
          <Title>{perfume.name}</Title>
          <Brand>{perfume.brand}</Brand>
          <Rating>
            {renderStars(perfume.rating)}
            <span style={{marginLeft: '10px'}}>({perfume.reviews_count} opinii)</span>
          </Rating>
          <Price>{perfume.price ? `${perfume.price} zł` : 'Cena na zapytanie'}</Price>
          <Button>
            <FaHeart /> Dodaj do ulubionych
          </Button>
        </InfoContainer>
      </FlexContainer>
    </Card>
    </CardLink>
  );
};

export default PerfumeCard;