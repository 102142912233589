import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { FaStar, FaArrowLeft, FaHeart } from 'react-icons/fa';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 20px;
  
  &:hover {
    color: var(--secondary-color);
  }
`;

const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImageSection = styled.div`
  text-align: center;
`;

const MainImage = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h1`
  font-size: 2em;
  color: var(--text-color);
`;

const Brand = styled.h2`
  color: var(--secondary-color);
  font-size: 1.2em;
`;

const Price = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  color: var(--text-color);
`;

const Description = styled.p`
  line-height: 1.6;
  color: var(--text-color);
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
  svg {
    color: gold;
  }
`;

const Button = styled.button`
  background: var(--primary-color);
  border: none;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 1.1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.2s;
  
  &:hover {
    background: var(--secondary-color);
    transform: translateY(-2px);
  }
`;

const ReviewsSection = styled.div`
  margin-top: 40px;
`;

const Review = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
`;

const PerfumeDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [perfume, setPerfume] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPerfume = async () => {
      try {
        const response = await axios.get(`/api.php/getPerfume/${id}`);
        setPerfume(response.data);
      } catch (error) {
        console.error('Error fetching perfume details:', error);
        // Tymczasowe dane mockowe w przypadku błędu API
        setPerfume({
          id: id,
          name: "J'adore",
          brand: "Dior",
          description: "Luksusowy zapach dla kobiet, który łączy w sobie kwiatowe nuty ylang-ylang, róży damasceńskiej i jaśminu. Idealny na wieczorne wyjścia i specjalne okazje.",
          price: 499,
          rating: 4.8,
          reviews_count: 256,
          image: "https://picsum.photos/400/400",
          reviews: [
            { id: 1, user: "Anna", rating: 5, comment: "Przepiękny zapach, bardzo trwały!" },
            { id: 2, user: "Marta", rating: 4, comment: "Elegancki i wyrafinowany" }
          ]
        });
      }
      setLoading(false);
    };

    fetchPerfume();
  }, [id]);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar key={index} color={index < rating ? "gold" : "#ddd"} />
    ));
  };

  if (loading) {
    return <Container>Ładowanie...</Container>;
  }

  if (!perfume) {
    return <Container>Nie znaleziono perfum</Container>;
  }

  return (
    <Container>
      <BackButton onClick={() => navigate('/')}>
        <FaArrowLeft /> Powrót do listy
      </BackButton>
      
      <ProductContainer>
        <ImageSection>
          <MainImage src={perfume.image} alt={perfume.name} />
        </ImageSection>
        
        <InfoSection>
          <Brand>{perfume.brand}</Brand>
          <Title>{perfume.name}</Title>
          <Rating>
            {renderStars(perfume.rating)}
            <span>({perfume.reviews_count} opinii)</span>
          </Rating>
          <Price>{perfume.price} zł</Price>
          <Description>{perfume.description}</Description>
          <Button>
            <FaHeart /> Dodaj do ulubionych
          </Button>
        </InfoSection>
      </ProductContainer>

      <ReviewsSection>
        <h2>Opinie klientów</h2>
        {perfume.reviews?.map(review => (
          <Review key={review.id}>
            <Rating>{renderStars(review.rating)}</Rating>
            <p><strong>{review.user}</strong></p>
            <p>{review.comment}</p>
          </Review>
        ))}
      </ReviewsSection>
    </Container>
  );
};

export default PerfumeDetails;