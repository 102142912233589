import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GlobalStyle } from './styles/GlobalStyles';
import PerfumeList from './components/PerfumeList';
import PerfumeDetails from './components/PerfumeDetails';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<PerfumeList />} />
        <Route path="/perfume/:id" element={<PerfumeDetails />} />
      </Routes>
    </Router>
  );
}

export default App;